<template>
  <div>
    <label :for="input?.id" class="flex">
      <input
        ref="input"
        v-uid
        v-bind="$attrs"
        class="sr-only"
        :checked="modelValue"
        :disabled="isDisabled"
        type="checkbox"
        @input="updateValue"
      />
      <div
        class="relative inline-flex h-5 w-9 rounded-full md:h-6 md:w-11"
        :class="[
          isDisabled
            ? 'cursor-not-allowed bg-gray-200'
            : ['cursor-pointer', modelValue ? 'bg-orange' : 'bg-gray-400']
        ]"
      >
        <div
          class="absolute left-0.5 top-0.5 block h-4 w-4 rounded-full bg-white transition-all duration-300 md:h-5 md:w-5"
          :class="modelValue && 'translate-x-full'"
        ></div>
      </div>

      <span v-if="isLabelVisible" class="flex cursor-pointer items-center gap-2 pl-2">{{ label }}</span>
    </label>
    <span v-if="description" class="inline-block ml-[52px] mt-2 text-sm text-neutral-400">
      {{ description }}
    </span>
  </div>
</template>

<script setup lang="ts">
interface InputProps {
  label: string
  description?: string
  isLabelVisible?: boolean
  modelValue: boolean
  isDisabled?: boolean
}

withDefaults(defineProps<InputProps>(), {
  modelValue: false,
  isDisabled: false,
  isLabelVisible: true
})

defineOptions({
  inheritAttrs: false
})

const emit = defineEmits(['update:modelValue', 'on-change'])

const updateValue = (e: Event) => {
  const checked = (e.target as HTMLInputElement).checked
  emit('update:modelValue', checked)
  emit('on-change', checked)
}

const input = ref<null | HTMLElement>(null)
</script>
