<template>
  <div
    class="group relative m-0 w-full max-w-lg lg:ml-6 lg:w-fit first:lg:ml-0 xl:ml-12"
    @mouseenter="submenuShow"
    @mouseleave="submenuHide"
  >
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'button'"
      :to="
        data.reference ? localePathByType('PageRecord', data.reference?.slug!) : data.externalLink
      "
      class="relative block w-fit py-3 text-lg font-semibold lg:text-base xl:text-lg"
      :class="[
        {
          'text-orange lg:text-orange': data.reference !== null && $route.params.slug === data.reference?.slug!
        },
        Array.isArray(data.children) && data.children.length ? 'pr-8 lg:pr-6 xl:pr-8' : 'pr-0',
        store.theme === 'blue' ? 'lg:text-white' : 'text-blue lg:text-blue',
        data.reference !== null || data.externalLink
          ? 'cursor-pointer'
          : Array.isArray(data.children) && data.children.length
            ? 'cursor-pointer'
            : 'cursor-auto'
      ]"
      @click="onClick(data)"
    >
      {{ data.title }}
      <Icon
        v-if="Array.isArray(data.children) && data.children.length"
        name="chevron"
        size="xs"
        :color="
          isSubmenuVisible
            ? theme.colors.orange.DEFAULT
            : store.theme === 'blue' && isDesktop
              ? 'white'
              : theme.colors.blue.DEFAULT
        "
        :class="[isSubmenuVisible ? 'rotate-180' : 'rotate-0']"
        class="pointer-events-none absolute right-0 top-1/2 -translate-y-1/2 transition-transform duration-300"
      />
    </component>
    <Transition name="menu">
      <div
        v-show="isSubmenuVisible"
        v-if="Array.isArray(data.children) && data.children.length"
        class="relative z-50 w-full bg-white lg:absolute lg:-left-6 lg:top-full lg:w-fit lg:min-w-[223px] lg:rounded-3xl lg:p-6 lg:shadow-2xl"
      >
        <div :class="{ 'lg:flex': idx === 0 }">
          <ul
            v-if="Array.isArray(data.children) && data.children.length"
            class="mt-3 text-blue first:mt-0 lg:mb-0 lg:grid-cols-12 lg:rounded-3xl lg:bg-white"
            :class="{ 'lg:flex-[624px]': idx === 0 }"
          >
            <li
              v-for="item in childrenFiltered"
              :key="item?.id"
              class="col-span-6 mt-3 first-of-type:mt-0"
            >
              <NuxtLink
                :to="
                  item?.reference
                    ? localePathByType(
                        'PageRecord',
                        item?.reference?.slug as string,
                        item?.query?.name as string
                      )
                    : item?.externalLink
                "
                class="block pt-3 first:pt-0 hover:text-blue-100"
                :class="[
                  {
                    ' text-orange':
                      item?.reference !== null &&
                      !item?.query?.name &&
                      $route.params.slug === item?.reference.slug
                  },
                  {
                    ' text-orange':
                      item?.query?.name &&
                      item?.query?.name === route.query.category
                  }
                ]"
                @click="onClickChild"
              >
                <div class="text-base transition-colors">
                  {{ item?.title }}
                </div>
                <div v-if="item?.description" class="text-sm">
                  {{ item?.description }}
                </div>
              </NuxtLink>
            </li>
          </ul>
          <div
            v-if="Array.isArray(data.blocks) && data.blocks.length"
            class="lg:rounded-b-3xl"
            :class="idx === 0 ? 'lg:flex-[344px]' : 'w-full bg-white'"
          >
            <div>
              <div
                v-if="data.blocks[0].title"
                class="pt-3 text-base text-blue"
                :class="idx === 0 ? 'pt-3' : 'flex flex-col justify-center'"
              >
                {{ data.blocks[0].title }}
              </div>
              <ul
                v-if="Array.isArray(data.blocks[0].reference) && data.blocks[0].reference.length"
                class="text-blue"
              >
                <li v-for="item in data.blocks[0].reference" :key="item.title" class="col-span-6">
                  <NuxtLink
                    :to="localePathByType(item?.__typename as string, item?.slug as string)"
                    class="block"
                    @click="onClickChild"
                  >
                    <div class="mt-3 text-base">{{ item.title }}</div>
                  </NuxtLink>
                </li>
              </ul>

              <Button
                v-else-if="Array.isArray(data.blocks[0].buttons) && data.blocks[0].buttons.length"
                class="mt-3 !h-6 !text-base text-blue"
                :label="data.blocks[0].buttons[0].title"
                :to="
                  data.blocks[0].buttons[0].pageLink !== null
                    ? localePathByType(
                        data.blocks[0].buttons[0].pageLink.__typename,
                        data.blocks[0].buttons[0].pageLink.slug
                      )
                    : data.blocks[0].buttons[0].externalLink
                "
                :target="data.blocks[0].buttons[0].externalLink ? '_blank' : undefined"
                variant="link"
                :icon="{
                  name: 'arrow-right',
                  size: 'xs',
                  color: theme.colors.blue.DEFAULT
                }"
                @click="onClickChild"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '~/stores/AppStore'
import { theme } from '#tailwind-config'
import type { MenuRecord } from '~/types/generated'

const store = useAppStore()
const breakpoints = useBreakpoints(theme.screens)
const isScreenLg = breakpoints.greater('lg')
const { isDesktop } = useDevice()
const route = useRoute()

const NuxtLink = resolveComponent('NuxtLink')
const { menuHide } = useMenu()

const { localePathByType } = useLocalePathByType()

// Main Submenu
const isSubmenuVisible = ref(false)

function submenuShow() {
  if (isScreenLg.value) {
    isSubmenuVisible.value = true
  }
}

function submenuHide() {
  if (isScreenLg.value) {
    isSubmenuVisible.value = false
  }
}

function submenuToggle() {
  isSubmenuVisible.value = !isSubmenuVisible.value
}

const props = defineProps({
  data: {
    type: Object as PropType<MenuRecord>,
    default: () => {}
  },
  idx: {
    type: Number,
    default: 0
  }
})

// Extract only published translations
const childrenFiltered = computed(() =>
  props.data.children.filter(x => x.publishTranslation === true)
)

function onClick(data: object) {
  if (Array.isArray(data.children) && data.children.length) {
    submenuToggle()
  } else {
    menuHide()
  }
}

function onClickChild() {
  submenuHide()
  menuHide()
}
</script>

<style lang="postcss">
.menu-enter-active {
  transition: transform 400ms var(--ease-out-back);
}

.menu-enter-from,
.menu-leave-to {
  transform: translateX(-10px);
}
</style>
