<template>
  <div>
    <Header>
      <MenuList :data="data.allMenus" :cta-data="data.allMenuCtas" />
      <MenuCta :data="data.allMenuCtas" class="absolute right-20 xl:right-[88px] top-6 hidden w-fit lg:block" />
      <button
        class="header__button header__checkout snipcart-checkout absolute right-[68px] inline-flex h-12 w-12 items-center justify-center rounded-full lg:right-4"
        :class="store.theme === 'gray' ? 'bg-white' : 'bg-light-gray'"
      >
        <Icon size="sm" name="shopping-cart" :color="theme.colors.blue.DEFAULT" />
        <span
          ref="snipcartCartCount"
          class="snipcart-items-count absolute -right-1 -top-1 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-orange text-xs font-normal text-white"
          >0</span
        >
      </button>
    </Header>
    <main>
      <slot />
    </main>
    <LazyFooter :data="data" />
    <CookieBanner />
  </div>
</template>

<script setup>
import { useAppStore } from '~/stores/AppStore'
import { theme } from '#tailwind-config'
import locales from '~/i18n/locales'

const store = useAppStore()
const { locale } = useI18n()
const langAttr = computed(() => locales.find(l => l.code === locale.value).code)
const snipcartCartCount = ref()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

useHead({
  htmlAttrs: {
    lang: langAttr
  }
})
</script>
