<template>
  <nav
    v-show="isMenuVisible"
    class="absolute left-0 top-0 h-[100dvh] w-full mt-16 lg:left-1/2 lg:-translate-x-1/2 lg:top-6 lg:h-auto lg:w-fit lg:m-0 lg:!block"
  >
    <div class="flex justify-between h-full flex-col overflow-scroll px-4 pb-24 pt-8 lg:h-auto lg:flex-row lg:overflow-visible lg:p-0">
      <div
        class="flex flex-col lg:flex-row"
      >
        <MenuListItem v-for="(item, idx) in parents" :key="idx" :idx="idx" :data="item" />
      </div>
      <MenuCta :data="ctaData" class="w-fit lg:hidden" />
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import { theme } from '#tailwind-config'
import { MenuCtaRecord, MenuRecord } from '~/types/generated'

const isMenuVisible = useState('menu')
const { menuHide } = useMenu()
const breakpoints = useBreakpoints(theme.screens)
const isScreenLg = breakpoints.greater('lg')

const props = defineProps({
  data: {
    type: Array as PropType<MenuRecord[]>,
    default: () => []
  },
  ctaData: {
    type: Array as PropType<MenuCtaRecord[]>,
    default: () => []
  }
})

// FIX delay on menu appear

// Get the top level menu items
const parents = computed(() => props.data.filter(item => item.parent === null))

// Handle window resize if menu is visible
const handleResize = useDebounceFn(() => {
  if (isScreenLg.value && isMenuVisible.value) {
    menuHide()
  }
}, 400)

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>
