<template>
  <Transition name="fade">
    <aside
      v-if="isBannerVisible"
      class="fixed bottom-0 left-1/2 z-10 w-full max-w-xl -translate-x-1/2 rounded-3xl border border-black bg-white sm:bottom-6 lg:max-w-5xl"
    >
      <div class="relative flex flex-col justify-between px-8 py-5 lg:flex-row">
        <div class="mb-5 flex-1 text-sm lg:mb-0 lg:pr-6">
          <div class="mb-2 font-semibold">
            {{ $t('cookies.title') }}
          </div>
          <i18n-t :keypath="'cookies.message'" tag="p" class="mb-6" scope="global">
            <template #privacyPolicy>
              <NuxtLink
                :to="$t('cookies.privacyLink')"
                class="underline hover:no-underline"
                noPrefetch
              >
                {{ $t('cookies.privacyPolicy') }}
              </NuxtLink>
            </template>
          </i18n-t>
          <button
            class="underline hover:no-underline"
            type="button"
            @click="showPreferences = true"
          >
            {{ t('cookies.preferences') }}
          </button>
        </div>
        <div class="flex w-full flex-wrap justify-end lg:w-fit lg:grow-0">
          <div class="flex w-full flex-wrap justify-end gap-6 lg:w-fit lg:grow-0">
            <Button
              class="!w-full md:!w-fit"
              variant="outline"
              :label="$t('cookies.decline')"
              @click="onDecline"
            />
            <Button class="!w-full md:!w-fit" :label="$t('cookies.acceptAll')" @click="onAccept" />
          </div>
        </div>
        <button class="absolute right-8 top-4 lg:hidden" @click="onDecline">
          <Icon name="close" />
        </button>
      </div>
      <Modal
        :show="showPreferences"
        class="max-w-[600px]"
        content-class="!px-4 !py-6"
        variant="fullscreen"
        :showClose="false"
        @on-close="() => (showPreferences = false)"
      >
        <div class="mb-4 font-semibold">
          {{ $t('cookies.preferences') }}
        </div>
        <i18n-t :keypath="'cookies.message'" tag="p" class="mb-12" scope="global">
          <template #privacyPolicy>
            <NuxtLink
              :to="$t('cookies.privacyLink')"
              class="underline hover:no-underline"
              noPrefetch
              @click="showPreferences = false"
            >
              {{ $t('cookies.privacyPolicy') }}
            </NuxtLink>
          </template>
        </i18n-t>
        <p class="mb-6">
          {{ $t('cookies.comply.manage') }}
        </p>
        <div class="mb-12 flex flex-col gap-4">
          <div v-for="item in preferences" :key="item.type" class="relative flex gap-2">
            <FormToggle
              v-model="state[item.type]"
              :name="item.type"
              :label="item.title"
              :description="item.description"
              :isDisabled="item.isRequired"
            />
          </div>
        </div>
        <Button :label="$t('cookies.allowSelection')" @click="onSave" />
      </Modal>
    </aside>
  </Transition>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm'
import { LocalStorageKey } from '~/types/localStorage'

enum CookieType {
  Necessary = 'necessary',
  Analytics = 'analytics',
  Advertising = 'advertising'
}

const { t } = useI18n()
const route = useRoute()

const isBannerVisible = ref(false)
const showPreferences = ref(false)

const preferences = ref([
  {
    type: CookieType.Necessary,
    title: t('cookies.comply.preferences.necessary.title'),
    description: t('cookies.comply.preferences.necessary.description'),
    isRequired: true
  },
  {
    type: CookieType.Analytics,
    title: t('cookies.comply.preferences.analytics.title'),
    description: t('cookies.comply.preferences.analytics.description')
  },
  {
    type: CookieType.Advertising,
    title: t('cookies.comply.preferences.advertising.title'),
    description: t('cookies.comply.preferences.advertising.description')
  }
])

const result = ref('Waiting for user interaction...')

const state = reactive({
  [CookieType.Necessary]: true,
  [CookieType.Analytics]: false,
  [CookieType.Advertising]: false
})

const gtm = useGtm()

const onDecline = () => {
  const accepted = [CookieType.Necessary]
  result.value = `User accepted the following cookies 📋: ${accepted.join(', ')}`
  localStorage.setItem(LocalStorageKey.CookieConsent, JSON.stringify(accepted))
  isBannerVisible.value = false
  gtm?.enable(false)

  // eslint-disable-next-line no-console
  console.log(result.value)
}

const onAccept = () => {
  const accepted = Object.values(CookieType)
  result.value = `User accepted all cookies. 👍: ${accepted.join(', ')}`
  localStorage.setItem(LocalStorageKey.CookieConsent, JSON.stringify(accepted))
  allConsentGranted()
  gtm?.enable(true)
  gtm?.trackView(route.name as string, route.path)

  isBannerVisible.value = false
  // eslint-disable-next-line no-console
  console.log(result.value)
}

const onSave = () => {
  const accepted = Object.values(CookieType).filter((type) => state[type])
  result.value = 'User accepted the following cookies 📋: ' + accepted.join(', ')
  localStorage.setItem(LocalStorageKey.CookieConsent, JSON.stringify(accepted))

  showPreferences.value = false
  isBannerVisible.value = false

  if (accepted.includes(CookieType.Advertising) || accepted.includes(CookieType.Analytics)) {
    if (accepted.includes(CookieType.Advertising) && accepted.includes(CookieType.Analytics)) {
      allConsentGranted()
    } else if (accepted.includes(CookieType.Analytics)) {
      analyticsConsentGranted()
    } else if (accepted.includes(CookieType.Advertising)) {
      advertisingConsentGranted()
    }
    gtm?.enable(true)
    gtm?.trackView(route.name as string, route.path)
  } else {
    gtm?.enable(false)
  }

  // eslint-disable-next-line no-console
  console.log(result.value)
}

function allConsentGranted() {
  gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted'
  })
}

function analyticsConsentGranted() {
  gtag('consent', 'update', {
    analytics_storage: 'granted'
  })
}

function advertisingConsentGranted() {
  gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted'
  })
}

onMounted(() => {
  useTimeoutFn(() => {
    isBannerVisible.value = !localStorage.getItem(LocalStorageKey.CookieConsent)

    if (
      localStorage.getItem(LocalStorageKey.CookieConsent)?.includes(CookieType.Analytics) ||
      localStorage.getItem(LocalStorageKey.CookieConsent)?.includes(CookieType.Advertising)
    ) {
      if (localStorage.getItem(LocalStorageKey.CookieConsent)?.includes(CookieType.Analytics)) {
        analyticsConsentGranted()
      } 
      if (
        localStorage.getItem(LocalStorageKey.CookieConsent)?.includes(CookieType.Advertising)
      ) {
        advertisingConsentGranted()
      }

      gtm?.enable(true)
      gtm?.trackView(route.name as string, route.path)
    }
  }, 1000)
})
</script>
