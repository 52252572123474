<template>
  <div
    v-if="Array.isArray(data) && data.length"
    class="flex flex-col gap-[10px] pt-8 lg:w-fit lg:basis-[10%] lg:flex-row lg:pt-0"
  >
    <template v-for="button in data" :key="button.id">
      <Button
        v-if="button"
        :label="button.title && button.title"
        class="!h-12"
        icon-circle-class="!h-8 !w-8 !ml-3"
        :class="[store.theme === 'blue' && '!text-neutral-50']"
        :to="
          button.reference !== null
            ? localePathByType(
                button.reference?.__typename as string,
                button.reference?.slug as string
              )
            : button.externalLink
        "
        :target="button.externalLink ? '_blank' : undefined"
        variant="outline"
        noPrefetch
        icon
        @click="menuHide"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '~/stores/AppStore'
import type { MenuCtaRecord } from '~/types/generated'
const store = useAppStore()

const { menuHide } = useMenu()
const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Array as PropType<MenuCtaRecord[]>,
    default: () => {}
  }
})
</script>
