<template>
  <header
    class="relative z-10 transition-opacity duration-150"
    :class="[
      isMounted ? 'opacity-100' : 'opacity-0'
    ]"
  >
    <MenuBackDrop />
    <div class="container relative z-50 flex h-[72px] items-center lg:h-24">
      <Logo :color="store.theme === 'blue' ? 'white' : '#314863'" />
      <slot />
    </div>
    <MenuButton />
  </header>
</template>

<script setup lang="ts">
import { useAppStore } from '~/stores/AppStore'

const store = useAppStore()
const isMounted = ref(false)

onMounted(() => {
  isMounted.value = true
})

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

watch(() => store.theme, () => {
  if (store.theme === 'blue') {
    document.body.classList.add('synchronized-header-bg')
  } else {
    document.body.classList.remove('synchronized-header-bg')
  }
})
</script>

<style lang="postcss">
.synchronized-header-bg {
  background: linear-gradient(#314863, #314863) top no-repeat;
  background-size: 100% 100vh;
}
</style>
